const translations = {
	en: {
		header: {
			welcome:
				"Welcome to my portolio with information about me, my skills and some projects",
		},
		navbar: {
			last_projects: "Last projects",
			overview: "Overview",
		},
		projects: {
			title: "Last projects",
		},
		overview: {
			title: "Overview",
			technologies: "Technologies",
			experience: "Experience",
			companys_website: "Company's website",
			company_1: {
				period: "2020 May - 2022 June",
				description: `<p>I worked for two years in the development of websites and applications for different clients, using different technologies according to the needs of each project.</p>
                <p>I have participated in more than ten large-scale projects and many smaller ones.</p>`,
			},
			company_2: {
				period: "2022 June - 2023 November",
				description: `<p>I was main developer of multiple projects such as websites, administration panels and APIs for mobile applications.</p>
                <p>The biggest project I worked on (and I was also the only developer) was an ERP system in which more than a hundred people work on a daily basis.</p>`,
			},
			company_3: {
				period: "2023 November - Current",
				description: `<p>I am currently working as a full time frontend developer, using technologies such as VueJS 3, JS and SCSS.</p>
                <p>I am using Agile and Scrum methodologies for the development of the various projects I am working on.</p>`,
			},
			about_me: "About me",
			about_me_description_1: `I am a FrontEnd developer with advanced knowledge in VueJS, JS, SASS and animations. I specialize in web layout, responsive and animations, and I would like to focus my career in that direction.`,
			about_me_description_2: `I consider myself a responsible, hardworking and eager to learn person.`,
			repository: "Repository",
			repository_description_1: `You can see all the code I have developed to make this website through my public repositories. I have also included a second repository of a backpanel designed from scratch to store some links of interest, code blocks, ...`,
			repository_description_2: `Since I started in the computer world, all the knowledge I have been acquiring has been on my own and by personal motivation.`,
			frontend_repository: "Frontend repository",
			backend_repository: "Backend repository",
		},
		footer: {
			back_to_top: "Back top",
		},
	},
	es: {
		header: {
			welcome:
				"Bienvenid@ a mi portafolio con información sobre mi, mis habilidades y algunos proyectos",
		},
		navbar: {
			last_projects: "Últimos proyectos",
			overview: "Resumen",
		},
		projects: {
			title: "Últimos proyectos",
		},
		overview: {
			title: "Resumen",
			technologies: "Tecnologías",
			experience: "Experiencia",
			companys_website: "Página web de la empresa",
			company_1: {
				period: "2020 Mayo - 2022 Junio",
				description: `<p>Trabajé durante dos años en el desarrollo de páginas webs y aplicaciones para diferentes clientes, usando tecnologías distintas según las necesidades de cada proyecto.</p>
                <p>Participé en más de diez proyectos de gran envergadura y en otros muchos más pequeños.</p>`,
			},
			company_2: {
				period: "2022 Junio - 2023 Noviembre",
				description: `<p>Fui main developer de múltiples proyectos como páginas webs, paneles de administración y APIs para aplicaciones móviles.</p>
                <p>El mayor proyecto en el que trabajé (siendo además el único desarrollador), fue un ERP en el que trabajan más de cien personas a diario.</p>`,
			},
			company_3: {
				period: "2023 Noviembre - Actualidad",
				description: `<p>Actualmente me encuentro trabajando como frontend developer a tiempo completo, usando tecnologías como VueJS 3, JS y SCSS.</p>
                <p>Me encuentro usando metodologías Agile y Scrum para el desarrollo de los diversos proyectos en los que estoy trabajando.</p>`,
			},
			about_me: "Sobre mí",
			about_me_description_1: `Soy un programador FrontEnd con conocimientos avanzados en VueJS, JS, SASS y animaciones. Me especializo en la maquetación web, responsive y animaciones, y me gustaría enfocar mi carrera en esa dirección.`,
			about_me_description_2: `Me considero una persona responsable, trabajadora y con ganas de aprender.
            Con nivel B2 de inglés y con ganas de seguir mejorando.`,
			repository: "Repositorio",
			repository_description_1: `Se puede ver todo el código que he desarrollado para hacer esta página web a través de mis repositorios públicos. También he includio un segundo repositorio de un backpanel diseñado desde cero para guardar algunos enlaces de interés, bloques de código, ...`,
			repository_description_2: `Desde que comencé en el mundo de la informática, todos los conocimientos que he ido adquieriendo han sido por mi cuenta y por motivación personal.`,
			frontend_repository: "Repositorio Frontend",
			backend_repository: "Repositorio Backend",
		},
		footer: {
			back_to_top: "Volver",
		},
	},
};

export default translations;
